<template>
  <div
    style="
      position: relative;
      user-select: none !important;
      overflow: hidden !important;
    "
  >
    <DialogTemplate
      oncontextmenu="return false;"
      :title="`${$t('tools.project.violations.dialog.vehiclelog.header', {
        id:
          data != null && data.id != null
            ? `Nr. ${$helper.formatID(data.id)}`
            : '',
      })} (${data.enterGateLog.licensePlate})`"
      v-on:close="$emit('close')"
    >
      <template v-slot:content>
        <!-- {{data}} -->
        <div class="pb-2">
          <v-row class="ma-0 pr-1" align="center" justify="center">
            <div class="image mb-3">
              <v-hover v-slot="{hover}">
                <div
                  style="
                    border-radius: 10px;
                    height: 100%;
                    width: 100%;
                    position: relative;
                  "
                >
                  <inner-image-zoom
                    :key="data.id"
                    hideHint
                    :src="data.enterGateLog.detectionImageUrl"
                  />

                  <v-fade-transition>
                    <IconButton
                      v-on:click="
                        $helper.download(
                          data.enterGateLog.detectionImageUrl,
                          `export-image-${data.licensePlate}-${
                            data.country
                          }-${+new Date(data.timestamp)}`,
                          'image',
                          'png',
                        )
                      "
                      v-show="hover"
                      class="download-overlay"
                      buttonColor="black"
                      icon="mdi-download"
                    />
                  </v-fade-transition>
                </div>
              </v-hover>
            </div>
            <v-spacer />
            <div class="image mb-3">
              <v-hover v-slot="{hover}">
                <div
                  style="
                    border-radius: 10px;
                    height: 100%;
                    width: 100%;
                    position: relative;
                  "
                >
                  <inner-image-zoom
                    :key="data.id"
                    hideHint
                    :src="data.exitGateLog.detectionImageUrl"
                  />

                  <!-- <v-img  style="border-radius: 10px;" :src="data.exitGateLog.detectionImageUrl"> -->

                  <!-- <v-fade-transition> -->

                  <IconButton
                    v-on:click="
                      $helper.download(
                        data.exitGateLog.detectionImageUrl,
                        `export-image-${data.licensePlate}-${
                          data.country
                        }-${+new Date(data.timestamp)}`,
                        'image',
                        'png',
                      )
                    "
                    v-show="hover"
                    class="download-overlay"
                    buttonColor="black"
                    icon="mdi-download"
                  />
                  <!-- </v-fade-transition> -->
                  <!-- </v-img> -->
                </div>
              </v-hover>
            </div>
          </v-row>
          <div style="width: 100%">
            <VehicleLogTimerange
              :data="{
                enterTimestamp: data.enterGateLog.timestamp,
                exitTimestamp: data.exitGateLog.timestamp,
                duration: data.duration / 60,
              }"
            />

            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.licensePlate')
              }}</template>
              <template v-slot:value>
                <v-hover v-slot="{hover}">
                  <v-row class="ma-0" align="center">
                    <b
                      class="link-value"
                      v-on:click="
                        $helper.copy2Clipboard(
                          data.enterGateLog.licensePlate,
                          'Kennzeichen',
                        )
                      "
                      >{{ data.enterGateLog.licensePlate }}</b
                    >
                    <v-icon
                      class="ml-2"
                      style="margin-top: 2px"
                      size="14"
                      color="primary"
                      v-show="hover"
                      >mdi-content-copy</v-icon
                    >
                  </v-row>
                </v-hover>
              </template>
            </TableListRow>

            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.country')
              }}</template>
              <template v-slot:value>
                <v-row class="ma-0 table-key" align="center">
                  <country-flag
                    style="border-radius: 10px"
                    :country="data.enterGateLog.country"
                    size="small"
                  ></country-flag>
                  <span class="ml-2">{{
                    $helper.countryName(data.enterGateLog.country)
                  }}</span>
                </v-row>
              </template>
            </TableListRow>

            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.whitelist')
              }}</template>
              <template v-slot:value>
                <div class="table-key">
                  <b
                    v-if="data.whitelist != null && data.whitelist.name != null"
                    >{{ data.whitelist.name }}</b
                  >
                  <div
                    v-else
                    style="font-size: 13px; margin-top: 1px"
                    class="grey--text"
                  >
                    {{ $t('tools.project.violations.table.noWhitelist') }}
                  </div>
                </div>
              </template>
            </TableListRow>

            <TableListRow v-if="data.whitelist != null">
              <template v-slot:key>{{
                $t('tools.project.violations.table.barrier')
              }}</template>
              <template v-slot:value>
                <v-row
                  v-if="
                    data.whitelist != null &&
                    data.whitelist.writeToDevice == true
                  "
                  style="font-size: 13px"
                  class="ma-0 table-key success--text"
                  align="center"
                >
                  <v-icon class="mr-1" color="success" size="17"
                    >mdi-check-circle</v-icon
                  >
                  <b>{{
                    $t('tools.project.violations.table.openedBarrier')
                  }}</b>
                </v-row>

                <div
                  v-else
                  style="font-size: 13px; margin-top: 1px"
                  class="grey--text table-key"
                >
                  {{ $t('tools.project.violations.table.noBarrierControl') }}
                </div>
              </template>
            </TableListRow>

            <v-divider class="mt-1 mb-1 mr-1 ml-1" style="opacity: 0.5" />
            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.enterGate')
              }}</template>
              <template v-slot:value>
                <span
                  v-if="
                    (data.enterGateLog.direction == 1 ||
                      data.enterGateLog.direction == 2) &&
                    data.enterGateLog.direction != null
                  "
                >
                  <b v-if="data.enterGateLog.gate != null">{{
                    data.enterGateLog.gate
                  }}</b>
                  <div
                    v-else
                    style="font-size: 13px"
                    class="grey--text table-key"
                  >
                    {{ $t('tools.project.log.gates.noName') }}
                  </div>
                </span>
                <div
                  v-else
                  style="font-size: 13px"
                  class="grey--text table-key"
                >
                  {{ $t('tools.project.log.gates.unknown') }}
                </div>
              </template>
            </TableListRow>

            <!-- <v-divider class="mt-2 mb-2 mr-1 ml-1" style="opacity: .5;" /> -->
            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.exitGate')
              }}</template>
              <template v-slot:value>
                <span
                  v-if="
                    (data.exitGateLog.direction == 1 ||
                      data.exitGateLog.direction == 2) &&
                    data.exitGateLog.direction != null
                  "
                >
                  <b v-if="data.exitGateLog.gate != null">{{
                    data.exitGateLog.gate
                  }}</b>
                  <div
                    v-else
                    style="font-size: 13px"
                    class="grey--text table-key"
                  >
                    {{ $t('tools.project.log.gates.noName') }}
                  </div>
                </span>
                <div
                  v-else
                  style="font-size: 13px"
                  class="grey--text table-key"
                >
                  {{ $t('tools.project.log.gates.unknown') }}
                </div>
              </template>
            </TableListRow>

            <v-divider class="mt-1 mb-1 mr-1 ml-1" style="opacity: 0.5" />

            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.duration')
              }}</template>
              <template v-slot:value>
                <span
                  class="font-weight-bold primary--text"
                  style="font-size: 14px"
                  ><span class="mr-1">+</span
                  >{{ $helper.formatDuration(data.duration / 60) }} h</span
                >
              </template>
            </TableListRow>

            <TableListRow>
              <template v-slot:key>{{
                $t('tools.project.violations.table.allowedTime')
              }}</template>
              <template v-slot:value>
                <div
                  v-if="
                    data.whitelist != null && data.whitelist.allowedTime != null
                  "
                >
                  <span
                    class="font-weight-bold black--text"
                    style="font-size: 14px"
                    >{{
                      $helper.formatDuration(data.whitelist.allowedTime)
                    }}
                    h</span
                  >
                  <span
                    class="font-weight-bold primary--text ml-2"
                    style="font-size: 12px"
                    ><span class="mr-1">+</span
                    >{{
                      $helper.formatDuration(
                        data.duration / 60 - data.whitelist.allowedTime,
                      )
                    }}
                    h</span
                  >
                </div>
                <span vL-else>-</span>
              </template>
            </TableListRow>

            <!-- <div style="position: relative;">
            <VehicleLogTimerange v-show="data.vehicleLog != null" class="mt-4" :data="data"/>
            <div style="position: absolute; right: 12px; top: 50%; transform: translateY(-50%);">
            <VehicleLogListInformation icon="mdi-chevron-right" :dense="false" :left="true" :data="data"/>
            
            </div>
            
            </div> -->
          </div>
        </div>

        <v-dialog v-show="isEditor" v-model="deleteVehicleLog" width="400">
          <DeleteDialogTemplate
            v-on:delete="deleteVehicleLogEvent"
            :loading="loadingArchive"
            :title="$t('tools.project.violations.dialog.deleteViolation.title')"
            v-on:close="deleteVehicleLog = false"
          >
            <template v-slot:content>
              {{ $t('tools.project.violations.dialog.deleteViolation.text') }}
            </template>
          </DeleteDialogTemplate>
        </v-dialog>
      </template>

      <template v-slot:actions>
        <v-row class="ma-0 pb-1" align="center" justify="start">
          <!-- <IconButton :disabled="!isEditor" v-on:click="deleteVehicleLog = !deleteVehicleLog" icon="mdi-delete"/> -->
          <v-spacer />
          <TextButton
            v-on:click="downloadLog()"
            icon="mdi-download"
            :text="$t('tools.project.violations.table.exportButton')"
          />
        </v-row>
      </template>
    </DialogTemplate>
  </div>
</template>

<script>
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
import TableListRow from '@components/table/TableListRow.vue';
import CountryFlag from 'vue-country-flag';
import TextButton from '@components/button/TextButton.vue';
// import LicensePlateTemplate from "../LicensePlateTemplate.vue";
import IconButton from '@components/button/IconButton.vue';
import DeleteDialogTemplate from '../../../../helper/dialog/DeleteDialogTemplate.vue';
import VehicleLogTimerange from '../VehicleLogTimerange.vue';
import InnerImageZoom from 'vue-inner-image-zoom';

export default {
  name: 'VehicleLogInformation',
  props: {
    data: {
      required: true,
    },
  },

  components: {
    DialogTemplate,
    TableListRow,
    CountryFlag,
    TextButton,
    IconButton,
    DeleteDialogTemplate,
    VehicleLogTimerange,
    'inner-image-zoom': InnerImageZoom,
  },

  data() {
    return {
      loadingArchive: false,
      deleteVehicleLog: false,
    };
  },

  computed: {
    isEditor() {
      if (this.$store.getters.user.internal == true) return true;

      if (
        this.$store.getters.user == null ||
        this.$store.getters.user.id == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general == null ||
        this.$store.getters.project.general.members == null
      )
        return false;
      var user = this.$store.getters.project.general.members.reduce((a, b) =>
        a.userId == this.$store.getters.user.id ? a : b,
      );

      if (user == null || user == false || user.canWrite == null) return false;
      else return user.canWrite;
    },
  },

  methods: {
    async deleteVehicleLogEvent() {
      this.loadingArchive = true;
      const resp = await this.$api.gatereport.changeVehicleLogStatus(
        this.data.id,
        0,
      );

      if (resp.success == true) {
        this.$toast.info(
          this.$t('tools.project.violations.toasts.info.archivedViolation'),
        );
        this.$emit('update');
        this.$emit('close');
      } else {
        this.$toast.error(
          this.$t('tools.project.violations.toasts.error.archivedViolation'),
        );
      }

      this.loadingArchive = false;
    },
    async downloadLog() {
      this.loadingDownload = true;

      const res = await this.$pdf.createVehicleLog(
        {...this.data, projectName: this.$store.getters.project.general.name},
        undefined,
        true,
      );
      if (res != true)
        return this.$toast.error(
          this.$t('tools.project.violations.toasts.error.creationProtocol'),
        );

      this.loadingDownload = false;
    },
  },
};
</script>

<style scoped>
.image {
  max-width: calc(50% - 5px);
  width: 100%;
  aspect-ratio: 16/9;
  overflow: hidden !important;
  user-select: none !important;
  border-radius: 10px;
  background-color: rgba(220, 220, 220, 1);
}

.license-plate-overlay {
  position: absolute;
  bottom: 0px;
  left: 10px;
}

.download-overlay {
  position: absolute;
  bottom: 8px;
  right: 10px;
  background-color: rgba(220, 220, 220, 0.75);
  border-radius: 50%;
}
.table-key {
  user-select: none !important;
}
.link-value {
  cursor: pointer;
  user-select: none !important;
}
.link-value:hover {
  color: var(--v-primary-base);
}
</style>
